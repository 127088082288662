export default function useEnv() {
    const isDevMode = import.meta.env.DEV
    const isProductionMode = import.meta.env.PROD
    const baseURL = import.meta.env.VITE_API_BASE_URL
    const devURL = import.meta.env.VITE_API_DEV_URL
    const localNetworkURL = import.meta.env.VITE_APP_LOCAL_NETWORK_URL
    const enableCollaborativeEditing: boolean =
        import.meta.env.VITE_ENABLE_COLLABORATIVE_EDITING === "true"

    const supportURL = import.meta.env.VITE_SUPPORT_URL
    const defaultFont = import.meta.env.VITE_DEFAULT_FONT
    const host = location.host
    const websocketsDisabled = import.meta.env.VITE_ECHO_BROADCASTER === "null"
    const websocketsEnabled = !websocketsDisabled

    const isUsingLiveWebsockets: boolean =
        import.meta.env.VITE_USE_LIVE_WEBSOCKETS === "true"

    const isUsingLocalWebsockets = !isUsingLiveWebsockets

    const visualSortBuffer = Number(import.meta.env.VITE_FIELD_SORT_Y_THRESHOLD)
    if (isNaN(visualSortBuffer))
        throw new Error("VITE_FIELD_SORT_Y_THRESHOLD is not a number")

    const usingStatelessHost = localNetworkURL === host
    const hostURL = usingStatelessHost ? devURL : baseURL

    return {
        baseURL,
        devURL,
        hostURL,
        usingStatelessHost,
        defaultFont,
        isProductionMode,
        isDevMode,
        supportURL,
        visualSortBuffer,
        websocketsDisabled,
        websocketsEnabled,
        enableCollaborativeEditing,
        isUsingLocalWebsockets,
        isUsingLiveWebsockets,
    }
}
